import FullScreenLoader from '@/components/FullScreenLoader/FullScreenLoader.vue'
import GraphQlMixin from '@/mixins/graphql.mixin'
import { mapMutations } from 'vuex'
import ReportFilter from '@/components/Report/ReportFilter/ReportFilter.vue'
import ReportDataTable from '@/components/Report/ReportForm/ReportDataTable/ReportDataTable.vue'
import ReportMixin from '@/mixins/report.mixin'

export default {
  props: ['id', 'filterId', 'filterProp'],
  components: {
    FullScreenLoader,
    ReportFilter,
    ReportDataTable
  },
  mixins: [GraphQlMixin, ReportMixin],
  data() {
    return {
      editor: null,
      validating: false,
      submitting: false,
      whereConditions: [],
      errorMessages: {
        name: []
      },
      report: {},
      columns: [],
      selectedColumns: [],
      reportHeaders: [],
      reportItems: [],
      reportItemsCount: 0,
      options: {
        page: 1,
        itemsPerPage: 25,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: true
      },
      search: '',
      debouncing: false,
      debounceTimeout: null,
      filter: {}
    }
  },
  computed: {
    subjects() {
      return this.$store.getters['graphql/reportTypes']
    },
    loading() {
      return this.$apollo.queries.report.loading
    },
    loaderMessage() {
      return this.loading
        ? 'Loading report'
        : this.submitting
        ? 'Submitting'
        : ''
    },
    fieldClass() {
      return {
        'px-0': this.$vuetify.breakpoint.xsAndDown,
        'px-4': this.$vuetify.breakpoint.smAndUp
      }
    },
    breadcrumbItems() {
      return [
        {
          text: 'Reports',
          disabled: false,
          to: { name: 'list-reports' },
          exact: true
        },
        { text: `Edit ${this.id}` }
      ]
    }
  },
  watch: {
    columns: {
      deep: true,
      handler(value) {}
    },
    selectedColumns: {
      deep: true,
      handler(value) {
        let newHeaders = []
        _.forEach(value, (v, k) => {
          newHeaders.push({
            text: v.label,
            value: v.name
          })
        })
        this.reportHeaders = newHeaders
      }
    },
    report: {
      handler(value) {
        this.columns = value.subject.fields
        let newHeaders = []
        _.forEach(value.headers, (v, k) => {
          newHeaders.push({
            text: v.label,
            value: v.name
          })
        })
        this.reportHeaders = newHeaders
        this.selectedColumns = _.difference(value.headers, this.columns)
        let selectedColumnNames = this.selectedColumns.map((x) => x.name)
        this.columns = _.filter(
          this.columns,
          (x) => !selectedColumnNames.includes(x.name)
        )
        this.columns = _.concat(this.columns, this.selectedColumns)
      },
      deep: true
    },
    // 'report.headers': {
    //   handler(value) {
    //     console.log('report headers changed')
    //     console.log(value)
    //     console.log('==================')
    //     this.reportHeaders = value
    //   },
    //   deep: true
    // },
    // 'report.subject': {
    //   handler(value) {
    //     console.log('subject changed!')
    //     console.log('selected columns are...')
    //     console.log(value)
    //     console.log(this.report.headers)
    //     this.reportHeaders = this.report.headers
    //     console.log('--------------------------')
    //     this.columns = value.fields
    //     //this.columns = _.difference(value.fields, this.report.headers)
    //   },
    //   deep: true
    // },
    filter: {
      handler() {
        this.reload()
      },
      deep: true
    },
    filterProp: {
      handler() {
        if (this.filterProp) {
          this.filter = this.filterProp
        }
      }
    }
  },
  methods: {
    getVariables() {
      let whereExpression = null
      whereExpression = this.filterToWhereExpression(this.filter)
      return {
        take:
          this.options.itemsPerPage === -1 ? null : this.options.itemsPerPage,
        skip:
          this.options.itemsPerPage === -1
            ? 0
            : this.options.itemsPerPage * (this.options.page - 1),
        orderBy: this.getOrderBy(),
        where: whereExpression,
        q: this.search
      }
    },
    reload() {},
    onFilterUpdate(filter) {
      this.filter = filter
    },
    required(v) {
      if (v instanceof Array && v.length === 0) {
        return 'Required'
      }
      return !!v || 'Required'
    },
    async validateName() {
      await this.validateField(this.report.name, 'name')
    },
    async validateName() {
      await this.validateField(this.report.name, 'name')
    },
    async validate() {
      this.validating = true

      // reset validation
      this.$refs.form.resetValidation()
      this.errorMessages = {
        name: []
      }

      // validate
      await this.validateName()
      const isValid =
        this.$refs.form.validate() &&
        _.every(this.errorMessages, (o) => o.length === 0)
      this.validating = false
      if (isValid) {
        this.submit()
      }
    },
    async validateField(value, path, additionalRules = []) {
      this.whereConditions = [{ path, comparison: 'equal', value }]
      if (this.id) {
        this.whereConditions.push({
          path: 'id',
          comparison: 'equal',
          value: this.id,
          negate: true
        })
      }
      // check other conditions if they have values
      for (let rule of additionalRules) {
        if (rule.value) {
          this.whereConditions.push(rule)
        } else {
          this.errorMessages[path].push(rule.path + ' not selected')
        }
      }

      if (!_.isEmpty(this.whereConditions)) {
        const { data, error } = await this.$apollo.query({
          // Query
          query: this.$gql`
            query reports($where: [WhereExpression]) {
              reports(where: $where) {
                totalCount
              }
            }
          `,
          variables: { where: this.whereConditions }
        })
        if (data && data.reports) {
          const reports = data.reports
          const msg = `Report with the same ${this.whereConditions[0].path} exists`
          if (reports.totalCount > 0) {
            this.errorMessages[this.whereConditions[0].path].push(msg)
          } else {
            this.errorMessages[this.whereConditions[0].path] = _.remove(
              this.errorMessages[this.whereConditions[0].path],
              msg
            )
          }
        } else if (error) {
          this.graphQLOnError(
            `Failed to validate report data with server. ${error.toString()}`
          )
        } else {
          this.graphQLOnError('Failed to connect to server.')
        }
      }
    },
    submit() {
      const input = {
        name: this.report.name,
        description: this.report.description,
        type: JSON.stringify(this.report.subject),
        relatedTypes: null,
        headers: JSON.stringify(this.selectedColumns),
        where: JSON.stringify(this.filterToWhereExpression(this.filter))
      }

      const fields = this.getFieldsGql('read', 'Report', [
        'id',
        'name',
        'description',
        'type',
        'relatedTypes',
        'headers',
        'where'
      ])

      // send by form type
      this.submitting = true
      if (this.id) {
        input.id = this.id
        this.$apollo
          .mutate({
            // Query
            mutation: this.$gql`
              mutation updateReport($input: UpdateReportInput!) {
                updateReport(input: $input) {
                  report {
                    ${fields}
                  }
                }
              }
            `,
            variables: { input }
          })
          .then(({ data }) => {
            const report = _.get(data, 'updateReport.report')
            this.setAppSnackbar({
              text: `Updated report #${report.id} ${report.name} successfully.`,
              color: 'success'
            })
            this.submitting = false
            this.$router.push({
              name: 'list-reports',
              params: { updatedItem: report }
            })
          })
          .catch((e) => {
            this.addError(
              null,
              `Failed to update report. ${e.toString()}`,
              'form'
            )
            this.submitting = false
          })
      } else {
        this.$apollo
          .mutate({
            // Query
            mutation: this.$gql`
              mutation createReport($input: CreateReportInput!) {
                createReport(input: $input) {
                  report {
                    ${fields}
                  }
                }
              }
            `,
            variables: { input }
          })
          .then(({ data }) => {
            const report = _.get(data, 'createReport.report')
            if (report && report.id && report.name) {
              this.setAppSnackbar({
                text: `Created report #${report.id} ${report.name} successfully.`,
                color: 'success'
              })
              this.submitting = false
              this.$router.push({
                name: 'list-reports',
                params: { updatedItem: report }
              })
            } else {
              this.addError(null, 'Failed to create report.', 'form')
              this.submitting = false
            }
          })
          .catch((e) => {
            this.addError(
              null,
              `Failed to create report. ${e.toString()}`,
              'form'
            )
            this.submitting = false
          })
      }
    },
    ...mapMutations(['setAppSnackbar'])
  },
  apollo: {
    report: {
      query() {
        const fields = this.getFieldsGql('read', 'Report', [
          'id',
          'name',
          'description',
          'type',
          'relatedTypes',
          'headers',
          'where'
        ])
        return this.$gql`
          query GetReport($id: ID!) {
            report(id: $id) {
              ${fields}
            }
          }
        `
      },
      variables() {
        return { id: this.id }
      },
      skip() {
        return !this.id
      },
      update: () => null,
      result({ data, error }) {
        const report = data.report
        if (error) {
          this.graphQLOnError(
            `Failed to get report data from server. ${error.toString()}`
          )
          this.report = {}
          if (error.toString().includes('Not found')) {
            this.renderError(404)
          }
        } else {
          this.report = {
            ...this.report,
            ...{
              id: report.id,
              name: report.name,
              subject: JSON.parse(report.type),
              description: report.description,
              headers: JSON.parse(report.headers),
              where: JSON.parse(report.where)
            }
          }

          this.filter = JSON.parse(report.where)
          this.$emit('name', this.report.name)
        }
      }
    },
    reportItems: {
      query() {
        let fields = this.getFieldsGql(
          'read',
          this.report.subject.graphTypeName,
          this.selectedColumns.map((x) => x.name)
        )

        return this.$gql`
          query GetReportItems($take: Int, $skip: Int, $orderBy: [OrderBy], $where: [WhereExpression], $q: String){
            reportItems: ${this.report.subject.listQueryName}(take: $take, skip: $skip, orderBy: $orderBy, where: $where, search: $q) {
              items {
                ${fields}
              }
              totalCount
            }
          }
        `
      },
      variables() {
        return this.getVariables()
      },
      skip() {
        return (
          !_.get(this, 'options.page') ||
          this.debouncing ||
          this.loadingQuery ||
          !this.report.subject ||
          this.selectedColumns.length === 0
        )
      },
      result({ data, error }) {
        if (data && data.reportItems) {
          this.reportItems = data.reportItems.items
          this.reportItemsCount = data.reportItems.totalCount
        } else if (error) {
          this.graphQLOnError(
            `Failed to get list of report items. ${error.toString()}`
          )
        }
      }
    }
  },
  mounted() {
    if (!this.id) {
    }
  }
}
