import CreateDialog from '@/components/Report/ReportFilter/CreateDialog/CreateDialog.vue'
// import SaveDialog from '@/components/Report/ReportFilter/SaveDialog/SaveDialog.vue'
// import LoadDialog from '@/components/Report/ReportFilter/LoadDialog/LoadDialog.vue'
// import FilterTreeview from '@/components/Report/ReportFilter/FilterTreeview/FilterTreeview.vue'
import { mapMutations } from 'vuex'

export default {
  props: ['filter', 'subject'],
  components: {
    CreateDialog
    // SaveDialog,
    // LoadDialog,
    // FilterTreeview
  },
  data() {
    return {
      localFilter: {},
      showCreateDialog: false,
      editReportFilterItem: null
    }
  },
  computed: {
    hasFilter() {
      return _.get(this.localFilter, 'filters.length', 0) > 0
    }
  },
  watch: {
    filter: {
      handler() {
        this.localFilter = this.filter
      },
      deep: true
    }
  },
  methods: {
    showSaveReportFilterDialog(item = null) {
      this.editReportFilterItem = item
      this.showSaveDialog = !this.showSaveDialog
    },
    onFilterCreated() {
      this.showLoadDialog = false
    },
    onFilterUpdated(reportFilter) {
      if (!_.isEmpty(reportFilter)) {
        reportFilter.settings = JSON.parse(reportFilter.settings)
        this.$refs.loadDialog.reportFilters =
          this.$refs.loadDialog.reportFilters.map((rf) =>
            rf.id === reportFilter.id ? reportFilter : rf
          )
      }
    },
    apply(filter) {
      this.localFilter = filter
      this.$emit('update', this.localFilter)
    },
    ...mapMutations(['setAppSnackbar'])
  },
  mounted() {
    this.localFilter = this.filter
  }
}
