import FilterRow from './FilterRow/FilterRow.vue'
import FilterGroup from './FilterGroup.vue'

export default {
  name: 'filterGroup',
  props: {
    group: { type: Object },
    root: { type: Boolean },
    subject: { type: Object}
  },
  components: { FilterRow, FilterGroup },
  data: () => ({}),
  methods: {
    addFilterGroup() {
      this.group.filters.push({
        negate: false,
        connector: 'and',
        filters: [
          {
            field: null,
            operator: null,
            value: null,
            negate: false,
            group: false,
            errors: []
          }
        ],
        group: true,
        root: false,
        errors: []
      })
    },
    emitRemove() {
      this.$emit('remove')
    },
    addFilter() {
      this.group.filters.push({
        field: null,
        operator: null,
        value: null,
        negate: false,
        group: false,
        errors: []
      })
    },
    removeFilter(i) {
      this.group.filters.splice(i, 1)
    },
    reset() {
      this.group.negate = false
      this.group.connector = 'and'
      this.group.filters = []

      // non-root filter group cannot be empty
      if (!this.group.root) {
        this.addFilter()
      }
    }
  }
}
